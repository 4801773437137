// src/controllers/infobar_controller.js
import { Controller } from '@hotwired/stimulus';
import { createFocusTrap, FocusTrap } from 'focus-trap';

const KEYCODE = {
  ESC: 27,
  TAB: 9,
};

const InfobarController = class extends Controller {
  buttons: NodeListOf<HTMLButtonElement>;
  focusTrap: FocusTrap;

  connect() {
    this.buttons = this.element.querySelectorAll('button');
    this.focusTrap = createFocusTrap(this.element as HTMLElement);

    this.buttons.forEach((buttonEl: HTMLButtonElement) => {
      const targetEl = document.getElementById(
        buttonEl.getAttribute('aria-controls') as string,
      );

      if (targetEl) {
        buttonEl.addEventListener('click', () => {
          this.toggleDialog(buttonEl, targetEl);
        });

        this.addEventListeners(buttonEl, targetEl);
      }
    });
  }

  toggleDialog(buttonEl: HTMLButtonElement, targetEl: HTMLElement) {
    let otherPanelOpen = false;

    this.buttons.forEach((btn: HTMLButtonElement) => {
      if (btn.getAttribute('aria-expanded') === 'true') {
        otherPanelOpen = true;
      }
    });

    // Reset old dialog
    this.buttons.forEach((btn: HTMLButtonElement) => {
      if (btn !== buttonEl && btn.getAttribute('aria-expanded') === 'true') {
        const targetId = btn.getAttribute('aria-controls');
        const target = targetId ? document.getElementById(targetId) : null;
        if (target) {
          this.closeDialog(btn, target, false);
        }
      }
    });

    if (buttonEl.getAttribute('aria-expanded') === 'true') {
      this.closeDialog(buttonEl, targetEl, otherPanelOpen);
    } else {
      // Open dialog
      this.openDialog(buttonEl, targetEl, !otherPanelOpen);
    }
  }

  openDialog(
    buttonEl: HTMLButtonElement,
    targetEl: HTMLElement,
    animate = true,
  ) {
    // Fix background
    document.documentElement.style.overflow = 'hidden';

    // Toggle button
    buttonEl.setAttribute('aria-expanded', 'true');

    // Open Dialog
    targetEl.style.display = 'block';
    if (animate) {
      targetEl.classList.add('animate');
    }
    targetEl.classList.add('show');

    this.focusTrap.activate();
  }

  closeDialog(
    buttonEl: HTMLButtonElement,
    targetEl: HTMLElement,
    animate = true,
  ) {
    // Make body scrollable again
    document.documentElement.style.overflow = 'auto';

    buttonEl.setAttribute('aria-expanded', 'false');
    if (!animate) {
      targetEl.classList.remove('animate');
    }
    targetEl.classList.remove('show');
    targetEl.style.removeProperty('display');

    this.focusTrap.deactivate();

    // Return focus to button
    buttonEl.focus();
  }

  checkCloseDialog(
    event: KeyboardEvent,
    buttonEl: HTMLButtonElement,
    targetEl: HTMLElement,
  ) {
    if (
      buttonEl.getAttribute('aria-expanded') === 'true'
      && event.keyCode === KEYCODE.ESC
    ) {
      this.closeDialog(buttonEl, targetEl);
      buttonEl.focus();
    }
  }

  addEventListeners(buttonEl: HTMLButtonElement, targetEl: HTMLElement) {
    // Listen for things that should close the dialog
    targetEl.querySelector('.drawer__close')?.addEventListener('click', () => {
      this.closeDialog(buttonEl, targetEl);
    });
    targetEl.querySelector('.drawer__mask')?.addEventListener('click', () => {
      this.closeDialog(buttonEl, targetEl);
    });

    // Close Dialog on escape
    document.addEventListener('keydown', (event) => {
      this.checkCloseDialog(event, buttonEl, targetEl);
    });
  }
};

export default InfobarController;

// src/controllers/navbar_controller.js
import { Controller } from '@hotwired/stimulus';
import { useWindowResize } from 'stimulus-use';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import { mediaBreakpointDown } from '../util/mediaquery';

const NavbarController = class extends Controller {
  declare readonly toggleTarget: HTMLButtonElement;
  declare readonly closeTarget: HTMLButtonElement;
  static targets = ['toggle', 'close'];

  wrapper: HTMLDivElement | null;
  tablist: HTMLUListElement | null;
  tabs: NodeListOf<HTMLAnchorElement> | null;
  panels: NodeListOf<HTMLDivElement> | null;
  focusTrap: FocusTrap;

  connect() {
    this.wrapper = this.element.querySelector('.navbar__wrapper');
    this.tablist = this.element.querySelector('.navbar__items');
    this.tabs = this.element.querySelectorAll('a[data-target]');
    this.panels = this.element.querySelectorAll('.navbar__dropdown');

    useWindowResize(this);

    this.tabs.forEach((anchor: HTMLAnchorElement) => {
      const { target } = anchor.dataset;
      const targetEl = target ? document.getElementById(target) : null;

      if (target && targetEl) {
        const heading = targetEl.querySelector('h3');
        const closeButtons = targetEl.querySelectorAll(
          '.navbar__dropdown__back, .navbar__dropdown__close',
        );

        anchor.setAttribute('role', 'button');
        anchor.setAttribute('aria-expanded', 'false');
        anchor.setAttribute('aria-controls', target);

        // Make heading focusable
        heading?.setAttribute('tabindex', '-1');

        closeButtons.forEach((button) => {
          button.addEventListener('click', () => {
            this.closeMega(anchor, targetEl);
          });
        });

        anchor.addEventListener('click', (event) => {
          event.preventDefault();
          this.toggleMega(anchor, targetEl);
        });

        targetEl.addEventListener('keydown', (event) => {
          if (event.key === 'Escape') {
            this.closeMega(anchor, targetEl);
          }
        });
      } else {
        console.warn('NAVBAR: No target element found');
      }
    });

    if (this.wrapper) {
      this.focusTrap = createFocusTrap(this.wrapper);

      this.toggleTarget.addEventListener('click', () => {
        this.toggleNavigation();
      });

      this.closeTarget.addEventListener('click', () => {
        this.toggleNavigation();
      });
    }
  }

  toggleNavigation() {
    if (this.wrapper?.classList.contains('is-visible')) {
      this.wrapper?.classList.remove('is-visible');

      if (mediaBreakpointDown('lg')) {
        this.focusTrap.deactivate();
        this.focusTrap.pause();

        // Make body scrollable again
        document.documentElement.style.overflow = 'auto';
      }
    } else {
      this.wrapper?.classList.add('is-visible');

      if (mediaBreakpointDown('lg')) {
        this.focusTrap.activate();

        // Fix background
        document.documentElement.style.overflow = 'hidden';
      }
    }
  }

  toggleMega(anchor: HTMLAnchorElement, targetEl: HTMLElement) {
    if (anchor.getAttribute('aria-expanded') === 'true') {
      this.closeMega(anchor, targetEl);
    } else {
      this.closeAllMegas();
      this.showMega(anchor, targetEl);
    }
  }

  showMega(anchor: HTMLAnchorElement, targetEl: HTMLElement) {
    anchor.setAttribute('aria-expanded', 'true');
    targetEl.classList.add('is-visible');
    if (mediaBreakpointDown('lg')) {
      this.focusTrap.updateContainerElements(targetEl);

      // Fix background
      document.documentElement.style.overflow = 'hidden';
    }
    targetEl.querySelector('h3')?.focus();
  }

  closeMega(anchor: HTMLAnchorElement, targetEl: HTMLElement) {
    anchor.setAttribute('aria-expanded', 'false');
    targetEl.classList.remove('is-visible');
    if (mediaBreakpointDown('lg')) {
      this.focusTrap.updateContainerElements(this.wrapper);
    }
    anchor.focus();
  }

  closeAllMegas() {
    this.tabs?.forEach((anchor: HTMLAnchorElement) => {
      anchor.setAttribute('aria-expanded', 'false');
    });

    this.panels?.forEach((mega) => {
      mega.classList.remove('is-visible');
    });
  }

  windowResize() {
    if (mediaBreakpointDown('lg')) {
      let megaOpen = false;

      // Check if one mega dropdown is open
      this.tabs.forEach((anchor: HTMLAnchorElement) => {
        if (anchor.getAttribute('aria-expanded') === 'true') {
          // Show the wrapper for the backdrop
          this.wrapper?.classList.add('is-visible');
          this.focusTrap.updateContainerElements(
            document.getElementById(anchor.dataset.target),
          );
          this.focusTrap.activate();

          megaOpen = true;

          // Fix background
          document.documentElement.style.overflow = 'hidden';
        }
      });

      if (!megaOpen && this.wrapper?.classList.contains('is-visible')) {
        // Check if wrapper is open
        this.focusTrap.updateContainerElements(this.wrapper);
        this.focusTrap.activate();

        // Fix background
        document.documentElement.style.overflow = 'hidden';
      }
    } else if (this.focusTrap) {
      // Deactivate focus trap for desktop
      this.focusTrap.pause();

      // Make body scrollable again
      document.documentElement.style.overflow = 'auto';
    }
  }
};

export default NavbarController;

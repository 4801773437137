import { Controller } from '@hotwired/stimulus';

const DropdownController = class extends Controller {
  panel: HTMLElement | null = null;

  connect(): void {
    const panelId = this.element.getAttribute('aria-controls');
    if (panelId) {
      this.panel = document.getElementById(panelId);
    }

    if (this.panel) {
      this.element.removeAttribute('hidden');
      this.element.setAttribute('aria-expanded', 'false');
      this.panel.setAttribute('hidden', 'true');

      this.element.addEventListener(
        'click',
        () => {
          this.toggle();
        },
        false,
      );
      this.panel.addEventListener('focusout', (event: FocusEvent) => {
        if (this.panel?.contains(event.relatedTarget as HTMLElement)) return;

        this.toggle();
      });
    } else {
      console.warn('DROPDOWN: No matching panel found!');
    }
  }

  toggle(): void {
    if (this.panel?.hasAttribute('hidden')) {
      this.element.setAttribute('aria-expanded', 'true');
      this.element.setAttribute('aria-controls', this.panelValue);
      this.panel.removeAttribute('hidden');
    } else {
      this.element.setAttribute('aria-expanded', 'false');
      this.panel?.setAttribute('hidden', 'true');
      this.element.removeAttribute('aria-controls');
    }
  }
};

export default DropdownController;

import { Controller } from '@hotwired/stimulus';
import { prefersReducedMotion } from '../util/mediaquery';

const VideoController = class extends Controller {
  declare readonly hasTypeValue: boolean;
  declare readonly typeValue: string;
  static values = { type: String };

  video: HTMLVideoElement | null;
  btnPlay: HTMLButtonElement | null;
  btnPause: HTMLButtonElement | null;

  connect(): void {
    this.video = this.element.querySelector('video');
    this.btnPlay = this.element.querySelector('.video__play');
    this.btnPause = this.element.querySelector('.video__pause');

    if (
      this.hasTypeValue
      && this.typeValue === 'loop'
      && !prefersReducedMotion()
    ) {
      this.video?.setAttribute('autoplay', 'true');
      this.video?.setAttribute('loop', 'true');
    }

    this.btnPlay?.addEventListener('click', () => {
      this.video?.play();
      this.video?.classList.remove('paused');
      this.video?.classList.add('plays');
    });
    this.btnPause?.addEventListener('click', () => {
      this.video?.pause();
      this.video?.classList.add('paused');
      this.video?.classList.remove('plays');
    });
  }
};

export default VideoController;

document.addEventListener('DOMContentLoaded', () => {
  const lazyImages = document.querySelectorAll('img.lazy');
  const lazyVideos = document.querySelectorAll('video.lazy');

  const setImageSrc = function (image: HTMLImageElement) {
    if (image.dataset.src) {
      image.setAttribute('src', image.dataset.src);
      image.removeAttribute('data-src');
    }
    if (image.dataset.srcset) {
      image.setAttribute('srcset', image.dataset.srcset);
    }
  };

  // https://web.dev/lazy-loading-images/
  // If the browser supports lazy loading, we can safely assign the src
  // attributes without instantly triggering an eager image load.
  if ('loading' in HTMLImageElement.prototype) {
    lazyImages.forEach((lazyImage) => {
      setImageSrc(lazyImage as HTMLImageElement);

      // On load remove the lazy class
      lazyImage.addEventListener('load', () => {
        lazyImage.classList.remove('lazy');
      });
    });
  } else if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target as HTMLImageElement;
          setImageSrc(lazyImage);
          lazyImage.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach((lazyImage) => {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    lazyImages.forEach((lazyImage) => {
      setImageSrc(lazyImage as HTMLImageElement);
      lazyImage.classList.remove('lazy');
    });
  }

  // https://web.dev/lazy-loading-video/
  if ('IntersectionObserver' in window) {
    const lazyVideoObserver = new IntersectionObserver((entries) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          const target = video.target as HTMLVideoElement;

          for (let i = 0; i < video.target.children.length; i += 1) {
            const child = video.target.children[i] as HTMLSourceElement;
            if (typeof child.tagName === 'string' && child.tagName === 'SOURCE' && child.dataset.src) {
              child.setAttribute('src', child.dataset.src);
            }
          }

          target.load();
          target.classList.remove('lazy');
          lazyVideoObserver.unobserve(target);
        }
      });
    });

    lazyVideos.forEach((lazyVideo) => {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
});

import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
const TabsController = class extends Controller {
  declare readonly tablistTarget: HTMLUListElement;
  declare readonly tabTargets: NodeListOf<HTMLAnchorElement>;
  declare readonly panelTargets: NodeListOf<HTMLAnchorElement>;
  static targets = ['tablist', 'tab', 'panel'];

  declare activeOptionId: string | null;

  connect(): void {
    // Add the tablist role to the first <ul> in the .tabbed container
    this.tablistTarget.setAttribute('role', 'tablist');

    // Add semantics are remove user focusability for each tab
    this.tabTargets.forEach((tab: HTMLAnchorElement) => {
      const parent = tab.parentNode as HTMLElement | null;
      const panelId = new URL(tab.href).hash.substring(1);
      const panel = document.getElementById(panelId);

      tab.setAttribute('role', 'tab');
      tab.setAttribute('id', `tab-${panelId}`);
      tab.setAttribute('tabindex', '-1');
      parent?.setAttribute('role', 'presentation');

      // Handle clicking of tabs for mouse users
      tab.addEventListener('click', (event: Event) => {
        event.preventDefault();
        this.activateTab(event.currentTarget as HTMLAnchorElement);
      });

      // Handle keydown events for keyboard users
      tab.addEventListener('keydown', (event: KeyboardEvent) => {
        // Get the index of the current tab in the tabs node list
        const index = Array.prototype.indexOf.call(
          this.tabTargets,
          event.currentTarget,
        );

        event.preventDefault();

        // Work out which key the user is pressing and
        // Calculate the new tab's index where appropriate
        switch (event.key) {
          // Go to previous tab
          case 'ArrowLeft':
            this.activateTab(this.tabTargets[index - 1]);
            break;
          // Go to next tab
          case 'ArrowRight':
            this.activateTab(this.tabTargets[index + 1]);
            break;
          case 'ArrowDown':
            // If the down key is pressed, move focus to the open panel,
            this.panelTargets[index].focus();
            break;
          case 'Home':
            // Activate first tab
            this.activateTab(this.tabTargets[0]);
            break;
          case 'End':
            // Activate last tab
            this.activateTab(this.tabTargets[this.tabTargets.length - 1]);
            break;
          default:
            break;
        }
      });

      // Add tab panel semantics and hide them all
      if (panel) {
        panel.setAttribute('role', 'tabpanel');
        panel.setAttribute('tabindex', '0');
        panel.setAttribute('aria-labelledby', `tab-${panelId}`);
        panel.hidden = true;
      }

      if (window.location.hash.substring(1) === panelId) {
        this.activateTab(tab, false);
      }
    });

    // Initially activate the first tab and reveal the first tab panel, if no tab is alread active
    const activeTab = this.tablistTarget.querySelector('[aria-selected]');
    if (!activeTab) {
      this.activateTab(this.tabTargets[0], false);
    }
  }

  // The tab switching function
  activateTab(newTab: HTMLAnchorElement | undefined, callFocus = true): void {
    const oldTab = this.tablistTarget.querySelector('[aria-selected]');

    if (newTab && newTab.id !== oldTab?.id) {
      if (callFocus) {
        newTab.focus();
      }
      // Make the active tab focusable by the user (Tab key)
      newTab.removeAttribute('tabindex');
      // Set the selected state
      newTab.setAttribute('aria-selected', 'true');
      // Get the indices of the new tabs to find the correct tab panels to show and hide
      this.panelTargets[
        Array.prototype.indexOf.call(this.tabTargets, newTab) + 1 - 1
      ].hidden = false;

      if (oldTab) {
        oldTab.removeAttribute('aria-selected');
        oldTab.setAttribute('tabindex', '-1');
        const oldIndex = Array.prototype.indexOf.call(this.tabTargets, oldTab);
        this.panelTargets[oldIndex].hidden = true;
      }
    }
  }
};

export default TabsController;

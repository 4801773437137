// src/controllers/notification_controller.js
import { Controller } from '@hotwired/stimulus';

const NotificationController = class extends Controller {
  static values = {
    timeout: Number,
  };

  connect() {
    if (this.hasTimeoutValue) {
      setTimeout(() => {
        this.close();
      }, this.timeoutValue);
    }
  }

  close() {
    this.element.remove();
  }
};

export default NotificationController;

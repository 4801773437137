import { Controller } from '@hotwired/stimulus';

const StudyplanController = class extends Controller {
  connect(): void {
    this.setupRelatedItems();
  }

  setupRelatedItems(): void {
    const releatedTriggers = this.element.querySelectorAll('.module[data-related]');
    releatedTriggers.forEach((item) => {
      const relatedItems = this.element.querySelectorAll(`[data-related="${item.dataset.related}"]`);
      item.addEventListener('mouseenter', () => {
        relatedItems.forEach((module) => {
          module.classList.add('show-related');
        });
      });
      item.addEventListener('mouseleave', () => {
        relatedItems.forEach((module) => {
          module.classList.remove('show-related');
        });
      });
    });
  }
};

export default StudyplanController;

// src/application.js
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

import './lazy';

// Start the Stimulus application.
const application = Application.start();
const controllers = import.meta.globEager('./controllers/**/*_controller.ts');
registerControllers(application, controllers);

// src/controllers/youtube_controller.js
import { Controller } from '@hotwired/stimulus';

const YoutubeController = class extends Controller {
  connect() {
    const embed = this.element.querySelector('[data-embed]');
    const videoId = embed.dataset.embed;

    this.element.addEventListener('click', () => {
      const iframe = document.createElement('iframe');

      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute(
        'allow',
        'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
      );
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('src', `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&showinfo=0&autoplay=1`);

      embed.appendChild(iframe);
      embed.classList.remove('d-none');
    });
  }
};

export default YoutubeController;

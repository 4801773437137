// src/controllers/notification_controller.js
import { Controller } from '@hotwired/stimulus';

const PreviewController = class extends Controller {
  declare readonly frameTarget: HTMLIFrameElement;
  static targets = ['frame'];

  setSize({ params }) {
    if (params.size === 'sm') {
      this.frameTarget.style.width = '480px';
    } else if (params.size === 'md') {
      this.frameTarget.style.width = '600px';
    } else if (params.size === 'lg') {
      this.frameTarget.style.width = '860px';
    }
  }
};

export default PreviewController;

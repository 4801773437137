import { Controller } from '@hotwired/stimulus';
import SelectorEngine from '../util/selector-engine';
import {
  getSelectorFromElement,
} from '../util/target-api';

const CarouselController = class extends Controller {
  declare readonly slideTargets: NodeListOf<HTMLDivElement>;
  declare readonly hasInnerTarget: boolean;
  declare readonly innerTarget: HTMLDivElement;
  static targets = ['slide', 'inner'];

  connect() {
    this.slideTargets.forEach((slide) => {
      slide.querySelector('a')?.setAttribute('tabindex', '-1');
    });

    const observerSettings = {
      root: this.element,
      rootMargin: '-10px',
    };

    if ('IntersectionObserver' in window) {
      const callback = function callback(slides) {
        slides.forEach((entry: IntersectionObserverEntry) => {
          entry.target.classList.remove('is-visible');
          const link = entry.target.querySelector('a');
          link?.setAttribute('tabindex', '-1');
          if (!entry.intersectionRatio > 0) {
            return;
          }
          entry.target.classList.add('is-visible');
          link?.removeAttribute('tabindex');
        });
      };

      const observer = new IntersectionObserver(callback, observerSettings);
      this.slideTargets.forEach((target) => observer.observe(target));
    }

    this.addEventListeners();
    this.toggleButtons();
  }

  addEventListeners(): void {
    const slideToElements = document.querySelectorAll('[data-slide], [data-slide-to]');
    let lastKnownScrollPosition = 0;
    let ticking = false;
    this.innerTarget.addEventListener('scroll', () => {
      lastKnownScrollPosition = this.innerTarget.scrollLeft;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.toggleButtons();
          ticking = false;
        });

        ticking = true;
      }
    });

    slideToElements.forEach((slideToElement) => {
      const selector = getSelectorFromElement(slideToElement as HTMLElement);
      if (selector) {
        const targets = SelectorEngine.find(selector);

        targets.forEach((target) => {
          if (target === this.element) {
            slideToElement.addEventListener('click', (event) => {
              event.preventDefault();
              this.dataApiClickHandler(slideToElement as HTMLElement);
            });
          }
        });
      }
    });
  }

  scrollToIndex(index: number) {
    const slide = this.slideTargets[index];

    if (slide) {
      this.scrollTo(slide as HTMLDivElement);
    }
  }

  scrollTo(slideToShow: HTMLDivElement) {
    if (this.hasInnerTarget) {
      const scrollPos = Array.prototype.indexOf.call(this.slideTargets, slideToShow)
        * (this.innerTarget.scrollWidth / this.slideTargets.length);
      this.innerTarget.scrollLeft = scrollPos;
    }
  }

  toggleButtons() {
    const btnPrevious = this.element.querySelector('.carousel__control-prev button');
    const btnNext = this.element.querySelector('.carousel__control-next button');

    // Hide previous button
    if (btnPrevious) {
      if (this.innerTarget.scrollLeft === 0) {
        btnPrevious.setAttribute('disabled', 'true');
      } else {
        btnPrevious.removeAttribute('disabled');
      }
    }

    // Hide next button
    if (btnNext) {
      if (
        this.innerTarget.scrollWidth
        === this.innerTarget.scrollLeft + this.innerTarget.clientWidth
      ) {
        btnNext.setAttribute('disabled', 'true');
      } else {
        btnNext.removeAttribute('disabled');
      }
    }
  }

  scrollInDirection(dir: string) {
    const visible = this.element.querySelectorAll('.carousel__inner .is-visible');
    const i = dir === 'prev' ? 0 : 1;

    if (visible.length > 1) {
      this.scrollTo(visible[i] as HTMLDivElement);
    } else {
      const newSlide = i === 0
        ? visible[0].previousElementSibling
        : visible[0].nextElementSibling;
      if (newSlide) {
        this.scrollTo(newSlide as HTMLDivElement);
      }
    }
  }

  showSlide(event: any) {
    const { index } = event.params;
    if (event.params?.index) {
      const newSlide = this.slideTargets[index];
      if (newSlide) {
        this.scrollTo(newSlide as HTMLDivElement);
      }
    }
  }

  previous() {
    this.scrollInDirection('prev');
  }

  next() {
    this.scrollInDirection('next');
  }

  dataApiClickHandler(trigger: HTMLElement) {
    if (trigger.dataset.slideTo) {
      const slideIndex = parseInt(trigger.dataset.slideTo, 10);

      if (typeof slideIndex === 'number') {
        this.scrollToIndex(slideIndex);
      }
    }

    if (trigger.dataset.slide) {
      const slideDirection = trigger.dataset.slide;

      if (slideDirection) {
        this.scrollInDirection(slideDirection);
      }
    }
  }
};

export default CarouselController;
